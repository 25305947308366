var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"shipments","fluid":"","tag":"section"}},[_c('v-dialog',{attrs:{"max-width":"600","fullscreen":_vm.$vuetify.breakpoint.xsOnly},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.parcel)?_c('v-card',[_c('v-row',{staticClass:"grey lighten-2"},[_c('v-col',{staticClass:"grow"},[_c('v-card-title',{staticClass:"text-h5 "},[_vm._v(" Fulfillment photos "+_vm._s(_vm.parcel.name)+" ")])],1),_c('v-col',{staticClass:"shrink"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',{staticClass:"mt-8 text-center"},[_c('p',{staticClass:"text-h6"},[_vm._v(" Fulfillment should contain: ")]),_vm._l((_vm.parcel.line_items),function(item,index){return _c('p',{key:("item-" + index),staticClass:"text-caption my-1"},[_vm._v(" "+_vm._s(item.quantity)+"x "+_vm._s(item.name)+" ")])}),_c('v-carousel',_vm._l((_vm.photos),function(photo,index){return _c('v-carousel-item',{key:index,staticClass:"ma-1",attrs:{"src":photo,"contain":""}})}),1)],2)],1):_vm._e()],1),_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.computed_items,"item-key":"name","options":_vm.options,"items-per-page":10,"footer-props":_vm.footer_props,"server-items-length":_vm.get_server_fulfillments,"no-data-text":"No fulfillments have yet been done","no-results-text":"No results","search":_vm.search,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("DateFilter")(item.created_at))+" ")]}},{key:"item.sf_photo_refs",fn:function(ref){
var item = ref.item;
return [(item.sf_photo_refs && item.sf_photo_refs.length)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.open_dialog(item)}}},[_c('v-icon',[_vm._v(" mdi-camera-outline ")])],1):_vm._e()]}},{key:"item.sf_label_refs",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.sf_label_refs.length),expression:"item.sf_label_refs.length"}],attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-printer-outline ")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((item.sf_label_refs),function(label,i){return _c('v-list-item',{key:("label-" + i),on:{"click":function($event){return _vm.open_label(label.ref)}}},[_c('v-list-item-content',[_vm._v(_vm._s(label.type))])],1)}),1)],1)]}},{key:"item.tracking_number",fn:function(ref){
var item = ref.item;
return [(item.tracking_url)?_c('a',{attrs:{"href":item.tracking_url,"target":"new"}},[_vm._v(_vm._s(item.tracking_number))]):(item.tracking_number)?_c('span',[_vm._v(_vm._s(item.tracking_number))]):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }