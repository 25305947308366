<template>
 <v-container
    id="shipments"
    fluid
    tag="section"
  >
  <v-dialog
  v-model="dialog"
  max-width="600"
  :fullscreen="$vuetify.breakpoint.xsOnly"
    >
  <v-card v-if="parcel">
        <v-row  class="grey lighten-2">
          <v-col class="grow">
            <v-card-title class="text-h5 ">
              Fulfillment photos {{ parcel.name }}
            </v-card-title>
          </v-col>
          <v-col class="shrink">
            <v-btn
              icon
              @click="dialog = false"
              ><v-icon>mdi-close</v-icon></v-btn>
          </v-col>
        </v-row>
        <v-card-text class="mt-8 text-center">
          <p class="text-h6">
            Fulfillment should contain:
          </p>
          <p v-for="(item, index) in parcel.line_items" :key="`item-${index}`" class="text-caption my-1">
            {{ item.quantity }}x {{ item.name }}
          </p>
          <v-carousel>
            <v-carousel-item
              v-for="(photo, index) in photos"
              :src="photo"
              :key="index"
              class="ma-1"
              contain
              />
          </v-carousel>
        </v-card-text>
  </v-card>
  </v-dialog>
    <div>
      <v-data-table
        :headers="headers"
        :items="computed_items"
        item-key="name"
        :options.sync="options"
        :items-per-page="10"
        :footer-props="footer_props"
        :server-items-length="get_server_fulfillments"
        no-data-text="No fulfillments have yet been done"
        no-results-text="No results"
        class="elevation-1"
        :search="search"
        :loading="loading"
      >
        <!-- <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Search"
            class="mx-4"
          ></v-text-field>
        </template> -->
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at | DateFilter }}
        </template>
        <template v-slot:[`item.sf_photo_refs`]="{ item }">
          <v-btn
            icon
            @click="open_dialog(item)"
            v-if="item.sf_photo_refs && item.sf_photo_refs.length"
            >
              <v-icon>
                mdi-camera-outline
              </v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.sf_label_refs`]="{ item }">
        <v-menu 
          offset-y
          >
        <template
          v-slot:activator="{ on, attrs }"
          >
          <v-btn
            small
            icon
            v-bind="attrs"
            v-on="on"
            v-show="item.sf_label_refs.length"
          >
            <v-icon>
              mdi-printer-outline
            </v-icon>
          </v-btn>
        </template>
        <v-list
          dense
          >
          <v-list-item
            v-for="(label, i) in item.sf_label_refs"
            :key="`label-${i}`"
            @click="open_label(label.ref)"
            >
            <v-list-item-content>{{ label.type }}</v-list-item-content>
          </v-list-item>
        </v-list>
        </v-menu>
        </template>
        <template v-slot:[`item.tracking_number`]="{ item }">
          <a v-if="item.tracking_url" :href="item.tracking_url" target="new">{{ item.tracking_number  }}</a>
          <span v-else-if="item.tracking_number">{{ item.tracking_number }}</span>
        </template>
        <!-- <template v-slot:[`footer.prepend`]>
          <p>TJENA</p>
        </template> -->
      </v-data-table>
    </div>
 </v-container>
</template>
<script>

import { mapState, mapGetters } from 'vuex'
import { DateFilter } from '@/utils/filters'
import firebase from 'firebase/app'
import 'firebase/storage'

export default {
  components: {
   
  },
  filters: {
    DateFilter
  },
  computed: {
    ...mapState({
      fulfillments: state => state.fulfillments,
    }),
    ...mapGetters({
      get_server_fulfillments: 'fulfillments/get_server_fulfillments',
    }),
    computed_items(){
      const { page, itemsPerPage } = this.options
      return this.fulfillments.parcels.slice(
        (page - 1) * itemsPerPage, page * itemsPerPage
      )
    }
  },
  watch: {
    options: {
      async handler (val) {
        console.log('options', val);
        this.loading = true
        await this.$store.dispatch('fulfillments/fetch_parcels', { rows: 
          val.page * val.itemsPerPage > this.get_server_fulfillments ? 
            this.get_server_fulfillments :
            val.page * val.itemsPerPage
          })
        this.loading = false
      },
      deep: true
    }
  },
  data() {
    return {
      loading: false,
      search: '',
      options: {},
      parcel: null,
      photos: [],
      dialog: false,
      footer_props: {
        'items-per-page-options': [
          5, 10, 25, 50, 100
        ]
      },
      headers: [
          {
            text: 'Fulfillment #',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          {
            text: 'Store',
            align: 'start',
            sortable: false,
            value: 'sf_ds_name',
          },
          {
            text: 'Fulfilled at',
            align: 'start',
            sortable: false,
            value: 'created_at',
          },
          {
            text: 'Customer',
            align: 'start',
            sortable: false,
            value: 'shipping_address.name',
          },
          {
            text: 'Country',
            align: 'start',
            sortable: false,
            value: 'shipping_address.country_code',
          },
          {
            text: 'Photos',
            align: 'center',
            sortable: false,
            value: 'sf_photo_refs',
          },
          {
            text: 'Prints',
            align: 'center',
            sortable: false,
            value: 'sf_label_refs',
          },
          {
            text: 'Tracking',
            align: 'start',
            sortable: false,
            value: 'tracking_number',
          },
      ]
    }
  },
  methods: {
    async open_label(ref){
      const url = await firebase.storage().ref().child(ref).getDownloadURL()
      window.open(url, 'new')
    },
  async open_dialog(item){
    console.log(item);
    this.parcel = item;
    this.dialog = true
    const photos = await Promise.all(
      item.sf_photo_refs.map(p => firebase.storage().ref().child(p.ref).getDownloadURL())
      )
    this.photos = photos
  }
  },
  async mounted(){
    // if(!this.fulfillments.is_fetched){
    //   await this.$store.dispatch('fulfillments/fetch_parcels')
    // }
    // this.loading = false
  }
}
</script>
<style lang="scss" scoped>
    
</style>